/**
 * This could arguably be an organism, but it's not feeling
 * complex enough to do that yet.
 */

.page-goals,
.page-states {
  h1 {
    .text--heading-1;
  }
  .zone-content {
    .two-col;
    .grid-12 {
      .two-col--75-percent;
    }
  }
}

.view-display-id-page {
  &.view-id-goals,
  &.view-id-states {
    .views-row {
      .teaser;
    }
  }
  &.view-id-states {
    h2 {
      margin-bottom: 1rem !important; // @TODO: find a better way to incorporate this
    }
  }
}