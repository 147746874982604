.example {
  margin-top: 1.875rem;
  h2 {
    padding-bottom: 0px;
  }
  .views-field-title {
    .text--heading-3;
    margin-bottom: .8125rem;
    .text--blue;
  }
  > span {
    .text--taxonomy;
    &:last-of-type {
      :before {
        content: " ";
      }
    }
  }
}

.example--widget {
  .field-label {
    .text--heading-2;
    .text--underline;
  }
  .field-items {
    .text--heading-3;
    color: @color--black;
    a {
      .text--blue;
    }
  }
}