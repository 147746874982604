.search {
  .container-inline {
    position: relative;
    display: inline-block;
  }
  input[type="text"] {
    .search-input;
  }
  input[type="submit"] {
    .search-button;
  }
}

.search--mobile {
  input[type="text"] {
    .search-input--mobile;
  }
  input[type="submit"] {
    .search-button--mobile;
  }
}