.teaser {
  border-bottom: 1px solid @color--grey-light;
  &:last-of-type {
    border-bottom: 0px;
  }
  h2 {
    .text--heading-3;
    .text--arrow;
    margin-top: 1rem;
    a {
      display: block;
      color: @color--blue;
      &:hover {
        color: @color--blue;
      }
    }
  }
  .node-readmore,
  .node-links {
    display: none;
  }
}