/**
 * Basic text formats
 */


.text--heading {
  /* atom: text--heading */
  font-family: @typeface--roboto;
  color: @color--navy;
  font-weight: 400;
  /* /text--heading */

}

.text--heading-1 {
  /* atom: text--heading-1 */
  .text--heading;
  font-size: 2.5rem;
  letter-spacing: .6px;
  line-height: 3rem;
  margin-bottom: 3rem;
  @media @query--mobile-small {
    letter-spacing: .45px;
    font-size: 1.875rem;
  }
  &--menu {
    color: @color--blue;
  }
  /* /text--heading-1 */
}

.text--heading-2 {
  /* atom: text--heading-2 */
  .text--heading;
  font-size: 1.875rem;
  letter-spacing: .32px;
  line-height: 1.167;
  @media @query--mobile-small {
    letter-spacing: .27px;
    font-size: 1.5625rem;
  }
  /* /text--heading-2 */
}
h2 {
  .text--heading-2;
}

.text--heading-3 {
  /* atom: text--heading-3 */
  .text--heading;
  font-size: 1.25rem;
  letter-spacing: .3px;
  margin-bottom: 1rem;
  @media @query--mobile-small {
    letter-spacing: .27px;
    font-size: 1.5625rem;
  }
  &--blue {
    color: @color--blue;
  }
  &--white {
    color: @color--white;
  }
  /* /text--heading-3 */
}
h3 {
  .text--heading-3;
}

.text--menu {
  /* atom: text--menu */
  font-family: @typeface--opensans;
  font-weight: bold;
  font-size: .875rem;
  letter-spacing: .21px;
  color: @color--white;
  text-transform: uppercase;
  a, a:hover, .text {
    color: @color--white;  // fixes annoying Zen attempts to color-overwrite
  }
  /* /text--menu */
}

.text--taxonomy {
  /* atom: text--taxonomy */
  font-family: @typeface--opensans;
  font-weight: bold;
  font-size: .875rem;
  letter-spacing: .21px;
  color: @color--grey-dark;
  margin-bottom: 0rem;
  &--link {
    color: @color--blue;
  }
  /* /text--taxonomy */
}

.text--paragraph {
  /* atom: text--paragraph */
  font-size: 1rem;
  font-family: @typeface--opensans;
  letter-spacing: .43px;
  line-height: 1.625rem;
  /* /text--paragraph */
}

.text--footer {
  /* atom: text--footer */
  font-size: .875rem;
  font-family: @typeface--opensans;
  font-weight: 600;
  letter-spacing: .38px;
  line-height: 1.5;
  /* /text--footer */
}

.text--blue {
  /* atom: text--blue */
  color: @color--blue;
  a, a:hover, .text {
    color: @color--blue; // fixes annoying Zen attempts to color-overwrite
  }
  /* /text--blue */
}

/**
 * Text decoration options
 */

.text--underline {
  /* atom: text--underline */
  border-bottom: 3px solid @color--grey-light;
  padding-bottom: .8125rem;
  /* /text--underline */
}

.text--arrow {
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdefs%3E%3C/defs%3E%3Cg%20id%3D%22Homepage%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Desktop-HD%22%20transform%3D%22translate%28-1341.000000%2C%20-353.000000%29%22%20stroke%3D%22%230076FF%22%20stroke-width%3D%222%22%3E%3Cg%20id%3D%22Sidebar%22%20transform%3D%22translate%281078.000000%2C%20275.000000%29%22%3E%3Cg%20id%3D%22Link-Arrow%22%20transform%3D%22translate%28273.000000%2C%2088.000000%29%20rotate%28-90.000000%29%20translate%28-273.000000%2C%20-88.000000%29%20translate%28264.000000%2C%2079.000000%29%22%3E%3Ccircle%20id%3D%22Oval%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%3E%3C/circle%3E%3Cpolyline%20id%3D%22Path-2%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20points%3D%224.84615385%207.61538462%208.87086839%2012.3743239%2012.9598107%207.7046274%22%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  display: block;
  background-position: right center;
  background-repeat: no-repeat;
  width: ~"calc(100% - 30px)";
  padding-right: 30px;
  margin-bottom: .4375rem;
}

.text--download {
  /* atom: text--download */
  .icon--download(@color--blue);
  display: block;
  padding-left: 34px;
  background-position: left center;
  background-repeat: no-repeat;
  /* /text--download */
}

.text--circle {
  border: 2px solid @color--blue !important; // flag needed because molecule--pager is a hasty mess
  border-radius: 50%;
  padding: .4375rem .6875rem;
  display: inline-block;
  box-sizing: content-box;
  margin-top: 1px;
}
