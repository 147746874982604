.page-search {
  .tabs.primary,
  .search-advanced,
  .info-date,
  .messages,
  label {
    display: none;
  }
  .search-form {
    .search;
    display: inline-block;
    border-bottom: 3px solid @color--blue; // ad hoc styling; @TODO fit into search atom somehow?
    margin-bottom: 3rem;
  }

  .search-result {
    margin-bottom: 2rem;
    h3 {
      .text--heading-3;
      margin-bottom: .8125rem;
      .text--blue;
    }
  }
}