.page-node {
  .field-type-image {
    .image--banner;
  }


  .group-left {
    h3 {
      .text--heading-2;
      .text--underline;
      margin-top: @margin--section;
    }
  }

  .group-right {
    .sidebar;
    .field {
      margin-bottom: 3rem;
    }
  }


  /**
   * Nodetype-specific CSS
   */

  &--goals {
    h1 {
      .text--taxonomy;
      color: @color--blue;
    }
    h2 {
      .text--heading-1;
      width: @width--75;
      @media @query--desktop-large {
        width: 100%;
      }
    }

    .view-id-examples {
      .examples;
      h2 {
        margin-bottom: 1.875rem; // reset for the h2 declaration above. @TODO: more elegant solution for this?
      }
    }
  }

  &.node-type-example {
    .field-name-field-button-link .field-item a {
      .button;
    }
    .group-right .field {
      .example--widget;
    }
  }

  .field-type-file a {
    .download;
  }


}
