/**
 * General front-only wrapper
 */

.front {
  h1 {
    width: @width--75;
    @media @query--desktop-large {
      width: 100%;
    }
  }
}

/**
 * Frontpage blocks
 *
 * Not sure if this is where I want this styling to go,
 * but it doesn't feel like the _wrong_ place, so...
 */

.block-goals-block {
  .tile-grid;
}

.block-howto {
  .block-title {
    .text--underline;
  }
}
