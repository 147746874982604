.tile {
  flex-basis: 307px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 1.3125rem;
  @media @query--desktop-large {
    flex-basis: ~"calc((100% / 3) - .875rem)";
  }
  @media @query--desktop-small {
    flex-basis: ~"calc((100% / 3) - .667rem)";
    margin-bottom: 1rem;
  }
  @media @query--tablet {
    flex-basis: ~"calc((100% / 2) - .5rem)";
  }
  @media @query--mobile {
    flex-basis: ~"calc(100% + 1.75rem)";
    margin-bottom: .625rem;
    margin-left: -.875rem;
  }

  a {
    color: @color--white; // underline fix
  }
  .background {
    padding: .8125rem;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    padding-bottom: 56.352%; // 307 x 173px by default

    &, * {
      display: flex;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      //
      // gradient below
      //
      /* IE9, iOS 3.2+ */
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjEwMCUiIHgyPSIwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuNyIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjAuNSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
      background-image: -webkit-gradient(linear, 0% 100%, 0% 0%,color-stop(0, rgba(0, 0, 0, 0.7)),color-stop(0.5, rgba(0, 0, 0, 0)));
      /* Android 2.3 */
      background-image: -webkit-linear-gradient(bottom,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 50%);
      /* IE10+ */
      background-image: linear-gradient(to top,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 50%);
      background-image: -ms-linear-gradient(bottom,rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 50%);
    }
  }
  h2 {
    .text--heading-3;
    color: @color--white;
    margin-top: auto;
    margin-bottom: 0;
    position: absolute;
    bottom: .8125rem;
    z-index: 3;
  }
}