// Inits
// ================================

/* --- [ Variables and Mixins ] --------------- */
@import "_fonts";
@import "_MQs";
@import "_colors";
@import "_utils";
@import "_keyframes";
@import "_ma_th";


// Atoms
// ================================
@import "atoms/atom--button";
@import "atoms/atom--text";
@import "atoms/atom--icons";
@import "atoms/atom--images";
@import "atoms/atom--menu-item";
@import "atoms/atom--search";

// Molecules
// ================================
@import "molecules/molecule--block";
@import "molecules/molecule--download";
@import "molecules/molecule--example";
@import "molecules/molecule--logo";
@import "molecules/molecule--menu";
@import "molecules/molecule--pager";
@import "molecules/molecule--search";
@import "molecules/molecule--teaser";
@import "molecules/molecule--thumbnail-and-body";
@import "molecules/molecule--tile";

// Organisms
// ================================
@import "organisms/organism--examples";
@import "organisms/organism--header";
@import "organisms/organism--footer";
@import "organisms/organism--sidebar";
@import "organisms/organism--tile-grid";

// Templates
// ================================
@import "templates/template--two-col";

// Pages
// ================================
@import "pages/page--global";
@import "pages/page--front";
@import "pages/page--node";
@import "pages/page--search";
@import "pages/page--state";
@import "pages/page--teaser";

// Global variables
// ================================

// @width--column: 291px;
// @width--gutter: 45px;
//
// @width--50: calc(@width--column * 2 + @width--gutter * 1);
// @width--75: calc(@width--column * 3 + @width--gutter * 2);

@width--column: 22.5%;

@width--50: 47.5%;
@width--75: 72.5%;

@margin--section: 2.25rem;

// Temporary hack BFC-81
#node-tile-spacer-23 {
  display: none;
}
