.search-input {
  width: 274px;
  height: 45px;
  border-radius: 22.5px;
  border: 0px;
  padding: 0 40px 0 22.5px;
  box-sizing: border-box;
  .text--paragraph;

  &:focus {
    outline: none;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: white;
  }
}

.search-input--mobile {
  @media @query--desktop-large {
    display: none;
  }
}

.search-button {
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2217px%22%20height%3D%2217px%22%20viewBox%3D%220%200%2017%2017%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdefs%3E%3C/defs%3E%3Cg%20id%3D%22Homepage%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Desktop-HD%22%20transform%3D%22translate%28-1325.000000%2C%20-38.000000%29%22%20stroke%3D%22%230076FF%22%20stroke-width%3D%222%22%3E%3Cg%20id%3D%22Header%22%20transform%3D%22translate%280.000000%2C%20-2.000000%29%22%3E%3Cg%20id%3D%22Group%22%20transform%3D%22translate%281326.000000%2C%2041.000000%29%22%3E%3Cellipse%20id%3D%22Oval-5-Copy-3%22%20cx%3D%225.6171875%22%20cy%3D%225.6171875%22%20rx%3D%225.6171875%22%20ry%3D%225.6171875%22%3E%3C/ellipse%3E%3Cpath%20d%3D%22M10.3744792%2C10.3744792%20L14.1192708%2C14.1192708%22%20id%3D%22Line-Copy-4%22%20stroke-linecap%3D%22round%22%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  border: none;
  color: transparent;
  background-color: white;
  text-shadow: none;
  border-radius: 0;
  width: 17px;
  height: 17px;
  padding: 0;
  position: absolute;
  right: 11.25px;
  bottom: ~"calc(50% - 10px)"; // icon height divided by 2, plus the height of the magnifying glass stem
                               // (because the circle should be centered)
  &:hover {
    cursor: pointer;
  }
}

.search-button--mobile {
  @media @query--desktop-large {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2217px%22%20height%3D%2217px%22%20viewBox%3D%220%200%2017%2017%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdefs%3E%3C/defs%3E%3Cg%20id%3D%22Homepage%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Desktop-HD%22%20transform%3D%22translate%28-1325.000000%2C%20-38.000000%29%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%222%22%3E%3Cg%20id%3D%22Header%22%20transform%3D%22translate%280.000000%2C%20-2.000000%29%22%3E%3Cg%20id%3D%22Group%22%20transform%3D%22translate%281326.000000%2C%2041.000000%29%22%3E%3Cellipse%20id%3D%22Oval-5-Copy-3%22%20cx%3D%225.6171875%22%20cy%3D%225.6171875%22%20rx%3D%225.6171875%22%20ry%3D%225.6171875%22%3E%3C/ellipse%3E%3Cpath%20d%3D%22M10.3744792%2C10.3744792%20L14.1192708%2C14.1192708%22%20id%3D%22Line-Copy-4%22%20stroke-linecap%3D%22round%22%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
    background-color: @color--navy;
    bottom: ~"calc(50% - 9px)";
  }
  @media @query--desktop-small {
    bottom: ~"calc(50% - 5px)"; // adjusts to shift in header height
  }
}
