/**
 * Default HTML values
 */

html {
  font-size: 16px;
}

body {
  .text--paragraph;
  background-color: @color--navy;
}

a,
a:link,
a:visited {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  .text--heading-1;
}



/**
 * Omega resets
 */

.grid-3,
.grid-4,
.grid-6,
.grid-8,
.grid-9,
.grid-12 {
  margin-left: 0;
  margin-right: 0;
}

/**
 * Wrappers
 */

  #zone-header,
  #zone-preface,
  #zone-content,
  #zone-footer {
    max-width: 1300px;
    margin: 0 auto;
    @media @query--desktop-large {
      margin: 0 2.625rem;
    }
    @media @query--desktop-small {
      margin: 0 1.875rem;
    }
    @media @query--mobile {
      margin: 0 .875rem;
    }
  }


/**
 * Header
 */

#zone-header-wrapper {
  .header;
}

/**
 * Content
 */
#zone-preface-wrapper {
  background-color: @color--white;
  padding: 3rem 0 0;
}

#zone-content-wrapper {
  background-color: @color--white;
  padding: 0 0 3rem;
}

/**
 * Footer
 */

#zone-footer-wrapper {
  .footer;
}


/**
 * Sidebars
 */

.right-sidebar {
  .zone-content {
    .two-col;
    .grid-9 {
      .two-col--75-percent;
    }
    .grid-3 {
      .two-col--25-percent;
      .sidebar;
    }
  }
}

.ds-2col {
  .two-col;
    .group-left {
      .two-col--75-percent;
      //flex-basis: 100%;
    }
    .group-right {
      .two-col--25-percent;
      //flex-basis: 100%;
      .sidebar;
    }
    &.node-goal {
      .group-right,
      .group-left {
        flex-basis: 100%;
      }
    }
}

#zone-footer {
  .two-col;
  .region {
    .two-col--50-percent;
  }
  #region-footer-second {
    text-align: right;
  }
}
