
/* --- [ Reusable Styles ] --------------- */

// Clearly (Micro-Clearfix)
// ================================

.clearly {
  // http://nicolasgallagher.com/micro-clearfix-hack/
  zoom:1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after { clear: both; }
}

.cf,
.clearfix {
  .clearly;
}



// Gradients
// ================================

.vert-grad(@start,@end) {
  @argbstart: argb(@start);
  @argbend: argb(@end);
  background: @end;
  background: -moz-linear-gradient(top,  @start 0%, @end 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@start), color-stop(100%,@end));
  background: -webkit-linear-gradient(top,  @start 0%,@end 100%);
  background: -o-linear-gradient(top,  @start 0%,@end 100%);
  background: -ms-linear-gradient(top,  @start 0%,@end 100%);
  background: linear-gradient(to bottom,  @start 0%,@end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@{argbstart}', endColorstr='@{argbend}',GradientType=0 );
}

.horiz-grad(@start,@end) {
  @argbstart: argb(@start);
  @argbend: argb(@end);
  background: @start;
  background: linear-gradient(to right, @start 0%,@end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@{argbstart}', endColorstr='@{argbend}',GradientType=1 );
}


.headings-no-margins() {
  h1, h2, h3, h4, h5, h6 { margin: 0; }
}


// Text transformations
// ================================

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.normalcase {
  text-transform: none;
  letter-spacing: normal;
}



// Text shadow
// ================================

#text-shadow {
  &.extrude-soft {
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.46);
  }
  &.extrude-hard {
    text-shadow: 0 1px 0px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.325), 0 2px 3px rgba(0, 0, 0, .25), 0 -1px 1px rgba(0, 0, 0, .12);
  }
}


// Transformations
// ================================
.t-x(@value) {
  transform+_: translateX(@value);
}
.t-y(@value) {
  transform+_: translateY(@value);
}
.t-rot(@value) {
  transform+_: rotate(@value);
}
.t-scale3d(@value) {
  transform+_: scale3d(@value);
}


// Transition
// ================================

.trans(@prop:all, @time:240ms, @mode:ease, @delay:0ms) {
  transition+: @prop @time @mode @delay;
}


// Positioning
// ================================

.abs(@top:auto; @right:auto; @bottom:auto; @left:auto) {
  position: absolute;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}
.abs-0 {
  .abs(0, 0, 0, 0);
}

// Font Smoothing
// ================================

.font-smoothing(@mode:antialiased) {
  -webkit-font-smoothing:@mode;
  & when (@mode = antialiased) {
    -moz-osx-font-smoothing: grayscale;
  }
  & when (@mode = subpixel-antialiased) {
    -moz-osx-font-smoothing: auto;
  }
}


// Placeholder
// ================================

.placeholder(@rules) {
  &::-webkit-input-placeholder { @rules(); }
  &:-moz-placeholder { @rules(); }
  &::-moz-placeholder { @rules();}
  &:-ms-input-placeholder { @rules(); }
}


// Element Invisible
// ================================

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  margin: 0 !important;
  padding: 0 !important;
}


// Inline Block Gap Killer
// ================================
.IB-gapfree(@selector: ~"> *", @align: middle) {
  letter-spacing: -1em;
  @{selector} {
    letter-spacing: normal;
    display: inline-block;
    vertical-align: @align
  }
}


// Positioning
// ================================

.abs-edges(@offset:0) {
  position: absolute;
  top: @offset;
  bottom: @offset;
  left: @offset;
  right: @offset;
}

.abs-reset {
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}


// Make Grid
// ================================

.makeGrid( @gridItems ; @gutter ; @cols ) {
  @length: length( @gridItems );
  @width: 100% / @cols;

  @gutter-unit: '';
  @gutter-top: '';
  @gutter-right:  '';
  @gutter-bottom: '';
  @gutter-left: '';
  @gutter-vert: '';
  @gutter-horiz: '';
  @margins: '';
  @padding: '';


  // 1
  .margins() when ( length(@gutter) = 1 ) {
    @gutter-unit: @gutter/2;
    margin: -1 * @gutter-unit;
  }
  .padding() when ( length(@gutter) = 1 ) {
    @gutter-unit: @gutter/2;
    @padding: @gutter-unit;
  }

  // 2
  .margins() when ( length(@gutter) = 2 ) {
    @gutter-vert: extract(@gutter, 1) / 2;
    @gutter-horiz: extract(@gutter, 2) / 2;
    margin: (-1 * @gutter-vert) (-1 * @gutter-horiz) ;
  }
  .padding() when ( length(@gutter) = 2 ) {
    @gutter-vert: extract(@gutter, 1) / 2;
    @gutter-horiz: extract(@gutter, 2) / 2;
    @padding: @gutter-vert @gutter-horiz;
  }

  // 3
  .margins() when ( length(@gutter) = 3 ) {
    @gutter-top: extract(@gutter, 1) / 2;
    @gutter-horiz: extract(@gutter, 2) / 2;
    @gutter-bottom: extract(@gutter, 3) / 2;
    margin: (-1 * @gutter-top) (-1 * @gutter-horiz) (-1 * @gutter-bottom);
  }
  .padding() when ( length(@gutter) = 3 ) {
    @gutter-top: extract(@gutter, 1) / 2;
    @gutter-horiz: extract(@gutter, 2) / 2;
    @gutter-bottom: extract(@gutter, 3) / 2;
    @padding: @gutter-top @gutter-horiz @gutter-bottom;
    @margins: (-1 * @gutter-top) (-1 * @gutter-horiz) (-1 * @gutter-bottom);
  }

  // 4
  .margins() when ( length(@gutter) = 4 ) {
    @gutter-top: extract(@gutter, 1) / 2;
    @gutter-right: extract(@gutter, 2) / 2;
    @gutter-bottom: extract(@gutter, 3) / 2;
    @gutter-left: extract(@gutter, 4) / 2;
    margin: (-1 * @gutter-top) (-1 * @gutter-right) (-1 * @gutter-bottom) (-1 * @gutter-left);
  }
  .padding() when ( length(@gutter) = 4 ) {
    @gutter-top: extract(@gutter, 1) / 2;
    @gutter-right: extract(@gutter, 2) / 2;
    @gutter-bottom: extract(@gutter, 3) / 2;
    @gutter-left: extract(@gutter, 4) / 2;
    @padding: @gutter-top @gutter-right @gutter-bottom @gutter-left;
    @margins: (-1 * @gutter-top) (-1 * @gutter-right) (-1 * @gutter-bottom) (-1 * @gutter-left);
  }

  letter-spacing: -1em;
  .margins();

}





// Toggle
// ================================

#ma-toggle() {

  .hamburger-menu {
    background: transparent;
    transition: background-color 150ms ease;

    &:hover { background: transparent; }
    &:focus { outline: none; }

    .text {
      display: inline-block !important;
      vertical-align: middle;
      width: 30px;
      height: 2px;
      background: @white;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      text-indent: -9999px;
      transition: background-color 150ms ease;

      &:before, &:after {
        display: inline-block;
        width: 30px;
        height: 2px;
        background: @white;
        position: absolute;
        left: 0;
        content: '';
        transform-origin: center;
        transition: transform 150ms ease, top 150ms ease, color 150ms ease;
      }
      &:before { top: 8px; }
      &:after { top: -8px; }
    }

    &.isOpen {
      border-bottom: 1px solid transparent;
      .text {
        background-color: transparent;
        box-shadow: none;
        &:before,
        &:after {
          opacity: 1;
          box-shadow: none;
          height: 6px;
        }
        &:before {
          top: 0;
          transform: rotate(-45deg);
        }
        &:after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  .search-menu {
    background: transparent;
    transition: background-color 150ms ease;

    &:hover { background: transparent; }
    &:focus { outline: none; }

    .text {
      display: inline-block !important;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      background-color: transparent;
      background-size: contain;
      background-image: @search--white;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      text-indent: -9999px;
      transition: background-color 150ms ease;

      &:before, &:after {
        display: inline-block;
        width: 30px;
        height: 2px;
        background: @white;
        position: absolute;
        left: 0;
        content: '';
        transform-origin: center;
        transition: transform 150ms ease, top 150ms ease, color 150ms ease;
        opacity: 0; // Don't show bars
      }
      &:before,
      &:after {
        top: 50%;
      }
      &:before { transform: rotate(90deg); }

    }

    &.isOpen {
      border-bottom-color: transparent;

      .text {
        background-size: 0;
        &:before,
        &:after {
          opacity: 1;
          top: 50%;
          background-color: @yellow;
        }
        &:before {
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .accordionSwitch {
    position: relative;
    padding-right: 60px;

    .toggle {
      display: block;
      .abs(0,0,0, auto);
      font-size: @caption-font-size;
      padding: 0;
      width: 60px;
      background-image: @dropdown-arrow--red;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1rem;
      .trans();

      &.isClosed {
        transform: scale3d(1,1,1);
      }
      &.isOpen {
        transform: scale3d(1,-1,1);
      }

      &:hover {
        cursor: pointer;
      }

      .text {
        .element-invisible();
        display: block;
        height: 100%;
      }
    }
  }

  .switch-desktop-hide {
    display: none;
    border-radius: 0;
    text-align: center;
    .text {
      display: none;
    }
  }

  .pane {
    transform-origin: top center;
    transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 1;

    &.isOpen {
      height: auto;
      transform: scale3d(1,1,1);
      opacity: 1;
    }
    &.isClosed {
      height: 0;
      transform: scale3d(1, 0, 0);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border: none;
      opacity: 0;
    }
  }

  .flip-pane {
    transform-origin: center;
    transition: transform 200ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 40;

    &.isOpen {
      max-height: 108px;
      transform: perspective(1000px) translateY(-50%) rotateX(0deg);
      opacity: 1;

      .resp(@tabletmax, {
        max-height: none;
        transform: perspective(1000px) translateY(0) rotateX(0deg);
      });
    }
    &.isClosed {
      max-height: 0;
      transform: perspective(1000px) translateY(-50%) rotateX(-90deg);
      transition: transform 200ms ease 0ms, opacity 100ms ease 0ms, max-height 0ms linear 200ms;
      border: none;
      opacity: 0;

      .resp(@tabletmax, {
        transform: perspective(1000px) translateY(0) rotateX(0deg);
      });
    }
  }

}


// Inline Icons
// ================================

.icon-before(@icon, @size:1rem, @absolute:true) {
  position: relative;
  padding: 0;
  padding-left: @size/2 + @size;
  display: block;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    background-image: @icon;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: @size @size;
    width: @size;
    height: @size;
    margin-top: .25rem;
  }

  & when (@absolute = false) {
    padding-left: 0;
    display: table;
    width: 100%;

    .text {
      display: table-cell;
      vertical-align: middle;
      padding-left: @size / 2;
    }
    &:before {
      position: static;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.icon-above(@icon) {
  position: relative;
  padding: 0;
  padding-top: 3rem;
  display: block;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    background-image: @icon;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }
}


.icon-bg(@icon; @size:1rem; @pos: @size/2 center) {
  position: relative;
  padding-left: @size*2;
  background-image: @icon;
  background-repeat: no-repeat;
  background-position: @pos;
  background-size: @size @size;
}

// Lists
// ================================
ul, ol {
  padding-left: .8rem;
  margin-top: 1.3rem;
  &:first-child {
    margin-top: 0;
  }
  li {
    ~ li {
      margin-top: .5rem;
    }
  }
}
ul {
  list-style: none;
  position: relative;
  li {
    &:before {
      content: '\2022';
      font-size: 1.1rem;
      float: left;
      position: absolute;
      left: 0;
    }
  }
}
.unbullet {
  list-style: none;
  padding: 0;
  li {
    margin: 0;
    &:before { content: none; }
  }
}
ul.menu,
ul.tabs {
  .unbullet;
}
