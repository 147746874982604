.footer {

  background-color: @color--navy;
  color: @color--blue-light;
  padding: 40px 0;
  > .region {
    display: flex;
  }
  p {
    .text--footer;
  }


  // Everything below could _probably_ be a molecule. @TODO?
  #boxes-box-disclaimer {
    font-style: italic;
    margin-top: 1rem;
    @media @query--tablet {
      margin-bottom: 3rem;
    }
    @media @query--mobile {
      text-align: center;
    }
  }
  #boxes-box-four_logo {
    img {
      margin-left: 2.25rem;
      vertical-align: middle;
      @media @query--desktop-large {
        margin-bottom: 1.75rem;
      }
      @media @query--tablet {
        margin-left: .5625rem;
        margin-right: .5625rem;
        @media (max-width: 620px) { // ad hoc image scaling
          width: ~"calc(25% - 1.5rem)";
        }
      }
      @media @query--mobile {
        width: auto;
      }
    }
    @media @query--tablet-min {
//      max-width: 22rem;
      margin-left: auto;
    }
    @media @query--tablet {
//      max-width: 22rem;
      text-align: center;
      margin-left: 0;
    }
    @media @query--mobile {
//      max-width: 22rem;
      margin-left: auto;
      margin-right: auto;
    }
    @media @query--tablet-min {
      max-width: 22rem;
      margin-left: auto;
    }
    @media @query--desktop-large-min {
      max-width: none;
      margin-left: auto;
      img {
        margin-left: 1rem;
      }
    }
  }
  #boxes-box-copyright_and_message {
    margin-top: 2.25rem;
    color: @color--blue;
    @media @query--tablet {
      margin-top: 0;
      text-align: center;
    }
    a {
      text-decoration: underline;
      color: @color--blue;
    }
    p {
      font-size: .75rem;
    }
  }
}
