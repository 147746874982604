.context-states {
  .group-left {
    .thumbnail-and-body;
    flex-basis: 100%;
  }
  .group-right {
    flex-basis: 0;
  }
  .view-id-examples {
    .examples;
    flex-basis: 100%;
  }
  article,
  .feed-icon {
    display: none;
  }
}
