// Width Declarations
// ================================

@width--mobile-small: 320px;
@width--mobile: 480px;

@width--tablet: 768px;

@width--desktop-small: 1024px;
@width--desktop-large: 1440px;

@tablesaw: @width--tablet;



// Min-Width Declarations
// ================================

/**
 * @width--XXXXX-min: (@width--XXXXX + 1px);
 *
 * (Do NOT forget those parentheses. Your code will compile,
 * and then it will break, in a way that will cost you two
 * hours' worth of investigation.)
 */

@width--mobile-small-min: (@width--mobile-small + 1px);
@width--mobile-min: (@width--mobile + 1px);

@width--tablet-min: (@width--tablet + 1px);

@width--desktop-small-min: (@width--desktop-small + 1px);
//@width--desktop-mid-min: (@width--desktop-mid + 1px);
@width--desktop-large-min: (@width--desktop-large + 1px);



// Media Queries — Min and Max
// ================================

/**
 * @query--XXXXX: ~"only screen and (max-width: @{width--XXXXX})";
 * @query--XXXXX-min: ~"only screen and (min-width: @{width--XXXXX-min})";
 */

@query--mobile-small: ~"only screen and (max-width: @{width--mobile-small})";

@query--mobile: ~"only screen and (max-width: @{width--mobile})";
//@query--mobile-min: ~"only screen and (min-width: @{width--tablet-min})";

@query--tablet: ~"only screen and (max-width: @{width--tablet})";
@query--tablet-min: ~"only screen and (min-width: @{width--tablet-min})";

@query--desktop-small: ~"only screen and (max-width: @{width--desktop-small})";
@query--desktop-small-min: ~"only screen and (min-width: @{width--desktop-small-min})";

@query--desktop-large: ~"only screen and (max-width: @{width--desktop-large})";
@query--desktop-large-min: ~"only screen and (min-width: @{width--desktop-large-min})";






// JS Breaks
// ================================
.generate_fake(@name) {
  &@{name} {
    &:before { content: %('%d', @@name); } // Must be string
  }
}

.js-breaks__ {
  &mobilemax { width: @width--mobile-small; }
  &babybear { width: @width--mobile; }
  &tabletmax { width: @width--tablet; }
  &goldilocks { width: @width--desktop-small; }
  &mamabear { width: @width--desktop-small; }
//  &papabear { width: @width--desktop-mid; }
  &tablesaw { width: @tablesaw; }
  &ursus { width: @width--desktop-large; }
  .generate_fake(width--mobile-small);
  .generate_fake(width--mobile);
  .generate_fake(width--tablet);
  .generate_fake(width--desktop-small);
//  .generate_fake(width--desktop-mid);
  .generate_fake(tablesaw);
  .generate_fake(width--desktop-large);
}
