
// --- [ fadein ] --------------- //

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}


// --- [ fadeinout ] --------------- //

@keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}