.menu {
  li {
    display: inline-block;
    .menu-item;
    .menu-item-inner {
      &:hover {
        color: @color--blue-light;
      }
    }
    a.active {
      .menu-item-inner {
        .menu-item--active;
      }
    }
  }
  @media @query--tablet {
    margin-top: 10px;
  }
}

.menu--sidebar {
  &__item {
    a {
      .text--heading-3;
      .text--arrow;
      color: @color--blue;
    }
  }
}