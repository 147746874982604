@header--padding: 20px;
@header--padding-tablet: 16px;

.header {

  background-color: @color--navy;
  padding: @header--padding 0;
  @media @query--tablet {
    padding: @header--padding-tablet 0;
  }
  .region-inner {
    display: flex;
    @media @query--tablet {
      flex-wrap: wrap;
    }
  }

  .block-boxes-logo {
    margin: auto;
    flex-basis: 100%;
    @media @query--tablet {
      flex-basis: ~"calc(100% - 47px)";
    }
    .boxes-box-controls {
      display: none;
    }
  }

  .block-main-menu {
    flex-shrink: 0;
    display: flex;
    @media @query--tablet {
      order: 3;
    }
    @media @query--mobile {
      margin-left: auto;
      margin-right: auto;
    }
    div, ul:not(.contextual-links), li, span {
      display: flex;
    }
    .block-title {
      display: none;
    }
  }

  form {
    margin-left: 37px;
    .search;
    .search--mobile;
    @media @query--desktop-large {
      margin-left: 47px;
    }
    @media @query--tablet {
      order: 1;
    }
  }
}

