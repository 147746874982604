@menu-item--border-width: 8px;

/**
 *
 * Structure note:
 * 1. ".menu-item" is called for an li.
 * 2. Beneath that li is an a, which may be active.
 * 3. Within that a is a span called "text".
 * 4. Within that span is a second span.
 *
 * I'm using the two spans to get active links' borders to bypass
 * the header's padding, and to both create the border while fixing
 * the anchor's vertical alignment.
 *
 */

.menu-item {
  /* atom: menu-item */
  .text--menu;
  display: inline-block;
  * {
    .text--menu; // fix for nested spans
  }
  &:hover {
    color: @color--blue-light;
  }

  a {
    display: flex;
    padding: 0 20px;
    @media @query--desktop-small {
      padding: 0 15px;
    }
    .text {
      display: flex;
      span {
        margin: auto;
      }
    }
    &:hover {
      color: @color--blue-light;
      text-decoration: none;
      * {
        color: @color--blue-light; // fix for nested spans
      }
    }
    &.active {
      color: @color--blue-light;
      .text {
        border-bottom: @menu-item--border-width solid;
        padding-bottom: @header--padding;
        margin-bottom: calc(@header--padding * -1);

        @media @query--tablet {
          padding-bottom: @header--padding-tablet;
          margin-bottom: calc(@header--padding-tablet * -1);
        }

        span {
          position: relative;
          top: calc(@menu-item--border-width / 2);
        }
      }
      * {
        color: @color--blue-light; // fix for nested spans
      }
    }
  }
  &:first-of-type a {
    @media @query--tablet {
      padding-left: 0;
    }
  }
  /* /menu-item */
}

.menu-item--active {
}