.image {
  /* atom: image */
  img {
    max-width: 100%;
  }
  /* /image */
}

.image--banner {
  /* atom: image--banner */
  .image;
  margin-bottom: 1rem;
  /* /image--banner */
}