.button {
  .text--menu;
  padding: 15px 50px;
  text-align: center;
  background-color: @color--blue;
  border-radius: 40px;
  display: inline-block;
  &:hover {
    background-color: @color--navy;
    text-decoration: none;
  }
}