.thumbnail-and-body {
  display: flex;
  flex-wrap: wrap;
  .field-type-image {
    flex-basis: 100px;
    margin-right: 1rem;
  }
  .field-type-image + div {
    flex-basis: ~"calc(100% - 100px - 1rem)";
    margin-top: 0;
  }
}