.logo {
  a {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  img {
    align-self: flex-start;
  }
  &__sub {
    @media @query--mobile {
      display: none;
    }
  }
  &--header {
    @media @query--desktop-small {
      &__sub {
        display: none;
      }
    }
  }
}