.pager {
  border: 0px;
  text-align: center !important;
  background-color: white;
  li,
  li a {
    background: none !important;
    background-color: white !important;
    border: none !important;
    float: none !important;
    .text--taxonomy;
    vertical-align: middle;
    &.pager-previous {
      float: left !important;
    }
  }
  li.pager-current {
    .text--circle;
    span {
      color: @color--blue;
    }
  }
  li.pager-previous {
    &,
    & a {
      color: transparent;
      .icon--arrow-previous(@color--blue) !important;
        background-position: right center !important;
        background-repeat: no-repeat !important;
        display: block;
        width: 28px;
        height: 34px;
    }
  }
  li.pager-next {
    &,
    & a {
      color: transparent;
      .icon--arrow-next(@color--blue) !important;
        background-position: right center !important;
        background-repeat: no-repeat !important;
        display: block;
        width: 28px;
        height: 34px;
        background-size: cover;
    }
  }
}