.examples {
  margin-top: @margin--section;
  h2 {
    .text--heading-2;
  }
  h3, h4 {
    .text--heading-3;
    display: inline-block;
  }
  h2, h3, h4 {
    .text--underline;
  }
  h4 {
    margin-bottom: 0;
    margin-top: 3.375rem;
  }
  .views-row {
    .example;
  }



  // @TODO: Find a cleaner way of doing these.
  .view-grouping:first-of-type .group-header:first-of-type h4 {
    margin-top: 0;
  }
  .view-grouping-content .group-header:first-of-type h4 {
    //margin-top: 3.375rem;
    margin-top: 0;
  }

  .view-grouping {
      > .view-grouping-header {
      h3 {
        margin-top: 3.375rem;
        border-bottom-color: @color--navy;
      }
    }
    &:first-of-type {
      .view-grouping-header h3 {
        margin-top: 0;
      }
    }
  }
}
