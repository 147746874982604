.two-col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:before,
  &:after {
    display: none;
  }
}

.two-col--25-percent {
  flex-basis: @width--column;
  flex-shrink: 0;
  flex-grow: 0;
  @media @query--desktop-small {
    flex-basis: 100%;
    margin-top: 3rem;
  }
}


.two-col--50-percent {
  flex-basis: @width--50;
  flex-shrink: 0;
  flex-grow: 0;
  @media @query--desktop-small {
    flex-basis: 50%;
  }
  @media @query--tablet {
    flex-basis: 100%;
  }
}

.two-col--75-percent {
  flex-basis: @width--75;
  flex-shrink: 0;
  flex-grow: 0;
  @media @query--desktop-small {
    flex-basis: 100%;
  }
}
